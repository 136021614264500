// Campaign.js

import React from 'react';
import './Campaign.css'; // Assuming you have your CSS file for styling

class Campaign extends React.Component {
  render() {
    return (
      <div className="campaignContainer">
        <div className="campaignSection">
          <h2 className="campaignTitle">CREATE CAMPAIGN</h2>
          <div className="imageContainer">
            <img
              className="campaignImage"
              src="images/image20.png" // Placeholder image URL
              alt="Campaign"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Campaign;
